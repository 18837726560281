span.arrow-up, span.arrow-down {
	font-family: var(--fa-style-family, "Font Awesome 6 Free");
	font-weight: var(--fa-style, 900);
	font-size: 1em;
	transform: none !important;
	border-width: 0 !important;
}


span.arrow-up::before {
	content: "\f0d8";
}

span.arrow-down::before {
	content: "\f0d7";
}

input:focus { box-shadow: 0 0 0 white !important; }

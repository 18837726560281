.leftpanel {
    width: 240px;
    color: #fff;
    float: left;
    clear: both;
    min-height: 800px;
    background-color: var(--left-panel-bg);
    box-shadow: var(--large-shadow);
    position:fixed;
    height:100%;
    z-index: 11;
    display: flex;
    flex-direction: column;
}


.leftmenu .nav-header {
    font-weight: normal;
    font-size: var(--font-size-l);
    padding: 15px 20px;
    text-shadow: none;
    border-bottom: 1px solid #222;
    margin-top: 0;
    color: #fff;
    line-height: 16px;
}

.nav li + .nav-header {
    margin-top: 0;
    background: var(--secondary-background);
}

.nav li + .nav-header small {
    text-transform: none;
    color: #999;
    line-height: 12px;
}

.leftmenu .project-selector {
    padding: 10px 15px 20px 15px;
    position: relative;

}

.leftmenu .project-selector label {
    display: block;
    clear: both;
    float: none;
    padding-top: 0;
    font-weight:lighter;
    /*font-family: 'RobotoLight', 'Helvetica Neue', Helvetica, sans-serif;*/
    font-size: var(--font-size-l);
}

.leftmenu .project-selector .chzn-container {
    width: 100% !important;
    display: block;
}

.leftmenu .project-selector .chzn-container .chzn-single {
    color: #555;
}

.leftmenu .project-selector .chzn-container .chzn-results li {
    color: #555;
}

.leftmenu .project-selector .chzn-container .chzn-results li.highlighted {
    color: #fff;
}

.leftmenu .project-selector .chzn-container .chzn-drop {
    width: 100% !important;
}

.leftmenu .project-selector  .projectListFilter {
    border-bottom:1px solid var(--main-border-color);
    padding:0px 15px;
}

.projectListFilter .fa {
    vertical-align: middle;
}

.leftmenu .project-selector  .projectListFilter select{
    border-color:var(--secondary-background);
    box-shadow:none;
    max-width: 135px;
    margin-top: 6px;
    margin-bottom: 6px;
    margin-right: 10px;
    padding-left: 0px;
}

.leftmenu .project-selector  .projectListFilter select:hover {
    border-color:var(--main-border-color);
}

.logo {
    width: 240px;
    text-align: center;
    height: 50px;
    padding:10px 5px 5px 15px;
    margin-left: 0;
    display: flex;

    flex-direction: column;
}

.logo a {
    width: 220px;
    text-align: center;
    height: 35px;
    background-position: left center;
    display: block;
    background-repeat: no-repeat;
    background-size:contain;
}

.logo a:hover {
    text-decoration:none;
}

.leftpanel a.barmenu {
    position:absolute;
    right:-50px;
    margin-top:0;
    margin-left:20px;
    font-size:20px;
    color:#fff;
    cursor:pointer;
    width:30px;
    height:50px;
    line-height:50px;
}

.leftpanel a.barmenu.open {

}

.leftpanel a.barmenu:hover {
    color:var(--header-action-hover-color);
}


.logo img {
    position: relative;
    height: 50px;
}

.leftmenu {
    flex: 1;
    flex-direction: column;
    height: 100%;
}

.nav {
    background: #f3f4f5;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.leftmenu .project-selector {
    padding:0;
    min-height:10px;
    display: flex;
    flex-direction: column;
    height: 60px;
}


.leftmenu .nav-tabs.nav-stacked .bigProjectSelector {
    display:block;
    font-weight:normal;
    font-size: var(--font-size-l);
    padding:0 15px;
    line-height:59px;
    height:60px;
    color:var(--project-selector-color);
    background:var(--project-selector-bg);
    border:none;
    overflow: hidden;
}

.leftmenu .nav-tabs.nav-stacked .bigProjectSelector i.fa {
    font-size: var(--base-font-size);
    position: absolute;
    top: 25px;
    right: 10px;
}

.leftmenu .nav-tabs.nav-stacked .bigProjectSelector .projectAvatar span.fa {
    margin-right:0;
}

.leftmenu .nav-tabs.nav-stacked .bigProjectSelector:hover,
.leftmenu .nav-tabs.nav-stacked .bigProjectSelector:active,
.leftmenu .nav-tabs.nav-stacked .bigProjectSelector:focus {
    text-decoration:none;
    background:var(--project-selector-hover-bg);
    color:var(--project-selector-hover-color);
}

.leftmenu .bigProjectSelector span {
    font-size: var(--base-font-size);
    font-weight:normal;
    color:#ccc;
}

.leftmenu .projectselector {
    left: 245px;

    z-index: 10000000;
    top:0;
    border-radius: var(--box-radius);
    min-width:375px;
}

.leftmenu .nav-tabs.nav-stacked .bigProjectSelector .projectAvatar {
    float:left;
    margin-top:15px;
    margin-right:10px;
}

.leftpanel .leftmenu .nav-tabs .projectselector  li.intro {
    color:var(--primary-font-color);
    padding:10px 15px 20px 15px;
}

.leftpanel .leftmenu .nav-tabs .projectselector  li.intro .title {
    font-size: var(--font-size-l);
}

.leftpanel .leftmenu .nav-tabs .projectselector  li.intro .sub {
    font-size: var(--base-font-size);
    color: var(--secondary-font-color);
}

.leftpanel .leftmenu .nav-tabs .projectselector  li.intro a {
    display:inline;
    padding:0;
    margin:auto;
}

.leftpanel .leftmenu .nav-tabs .projectselector  li.intro a:hover {
    background:var(--secondary-background);
    color:var(--secondary-action-color);

}

.leftpanel .leftmenu .nav-tabs .projectselector > li a:hover {
    color:var(--dropdown-link-hover-color);
}

.leftpanel .leftmenu .nav-tabs .projectselector  li.nav-header {
    display: block;
    padding: 3px 15px;
    font-size: var(--base-font-size);
    font-weight: bold;
    line-height: 20px;
    color: var(--secondary-font-color);
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
    text-transform: uppercase;
    background:var(--secondary-background);
}

.leftpanel .leftmenu .nav-tabs .projectselector  li.nav-header.border {
    border-top:1px solid var(--neutral);
    border-bottom:0;
    padding-top: 10px;
}

.leftpanel .leftmenu .nav-tabs .projectselector  li span.info {
    color:var(--primary-font-color);
    padding: 0 15px;
    display: block;
    margin-bottom: 15px;
}


.leftmenu .nav-tabs.nav-stacked .dropdown ul li.active a {
    color: #fff;
}

.leftmenu .nav-tabs.nav-stacked .dropdown ul li a:hover {
    text-decoration: none;
    background-color: #555;
    color: #fff;
}

.leftmenu .nav-tabs.nav-stacked .project-selector .chzn-container a {
    padding: 0 20px;
}

.leftmenu .nav-tabs.nav-stacked .project-selector .projectAvatar {
    width:30px;
    height:30px;
    font-size:14px;
    line-height:30px;
    text-align:center;
    background:var(--primary-font-color);
    color:var(--main-action-color);
    display:inline-block;
    border-radius:var(--box-radius-small);
    background:var(--element-gradient);
}

.leftmenu .nav-tabs.nav-stacked .project-selector .projectAvatar img {
    width:30px;
    height:30px;
    border-radius:var(--box-radius-small);
}

.leftmenu .nav-tabs.nav-stacked .project-selector .projectAvatar .fa {
    font-weight:900;
    color:#fff;
    margin-right:0;
}



.leftmenu .nav-tabs.nav-stacked a {
    color: #555;
    padding: 10px 20px 10px 20px;
    font-size: var(--font-size-l);
}

.leftmenu .nav-tabs.nav-stacked {
    background-color:var(--left-panel-bg);
}

.leftmenu .nav-tabs.nav-stacked a span {
    margin-right: 5px;
}

.leftmenu .nav-tabs.nav-stacked > li > a {
    border: 0;
    border-bottom: 1px solid #232323;
    border-top: 1px solid #232323;
}

.leftmenu .nav-tabs.nav-stacked > li > a:hover,
.leftmenu .nav-tabs.nav-stacked > li > a:focus {
    background-color: #2c2c2c;
    color: #fff;
    border-bottom-color: #232323;
}

.leftmenu .nav-tabs.nav-stacked > li.active > a {
    color: #fff;
    border-bottom-color: rgba(0, 0, 0, 0.2);
}

.leftmenu .nav-tabs.nav-stacked > li.active > a:hover {
}

.leftmenu .nav-tabs > li {
    margin-bottom: 0;
}

.leftmenu .nav-tabs.nav-stacked > li.dropdown ul {
    background: var(--main-menu-link-bg);
    display: none;
}

.leftmenu .nav-tabs.nav-stacked > li.dropdown ul.submenu li a{
    padding-left:35px;
}

.leftmenu .nav-tabs.nav-stacked > li.dropdown li.submenuToggle {
    margin-top:0;
    border-top:1px dotted var(--main-border-color);
}

.leftmenu .nav-tabs.nav-stacked > li.dropdown li.submenuToggle:first-child {
    margin-top:0;
}

.leftmenu .nav-tabs.nav-stacked > li.dropdown li.submenuToggle a{
    font-size: var(--font-size-s);
    text-transform: uppercase;
    padding: 10px 5px 10px 10px;
}

.leftmenu .nav-tabs.nav-stacked > li.dropdown li.submenuToggle a:has(.fa-angle-right){
}

.leftmenu .nav-tabs.nav-stacked > li.dropdown li.submenuToggle a strong {
    vertical-align: middle;
}


.leftmenu .nav-tabs.nav-stacked > li.dropdown li.title a {
    font-size: var(--font-size-xs);
    text-transform: uppercase;
    padding: 15px 5px 5px 25px;
}

.leftmenu .nav-tabs.nav-stacked > li.dropdown li.title a:hover {
    background:transparent;
    border-color:transparent;
    color:var(--main-menu-link-color);
    cursor:default;
}


.leftmenu .nav-tabs.nav-stacked > li.dropdown > a {
    background-repeat: no-repeat;
    background-position: right 19px;
}

.leftmenu .nav-tabs.nav-stacked .dropdown ul li a {
    display: block;
    font-size: var(--base-font-size);
    padding: 10px 10px 10px 10px;
    color: var(--main-menu-link-color);
    border-left:5px solid transparent;

}

.leftmenu .nav-tabs.nav-stacked .dropdown ul li.active a {
    background-color: var(--main-menu-link-active-bg);
    color: var(--main-menu-link-active-color);
    border-left-color: var(--main-menu-link-active-color);
}

.leftmenu .nav-tabs.nav-stacked .dropdown ul li a:hover {
    text-decoration: none;
    background-color: var(--main-menu-link-hover-bg);
    color: var(--main-menu-link-hover-color);
    border-left-color: var(--main-menu-link-hover-bg);
}

.fixedMenuPoint {
    background: var(--main-menu-link-bg);
    position:fixed;
    width:240px;
    bottom:0;
    box-shadow: 0 -10px 10px var(--main-menu-link-bg);
    padding-bottom: 10px;
}


.nav-tabs {
    border-color: var(--primary-color);
}

.nav-tabs > .active > a,
.nav-tabs > .active > a:hover,
.nav-tabs > .active > a:focus {
    border-color: var(--primary-color);
}

.nav-tabs > li > a {
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
}

.nav-tabs > li > a:hover, .nav-tabs > li > a:focus {
    border-color: var(--primary-color);
    background: var(--primary-color);
    color: #fff;
}

.nav-tabs li.separator {
    border-bottom:1px dashed var(--main-border-color);
    border-bottom-style: wave;
    height:5px;
    margin-bottom:5px;
}

.nav-tabs li .submenuCaret {
    width: 15px;
    float: right;
    padding-top: 8px;
    padding-right: 20px;
}



.headmenu {
    list-style: none;
    float: right
}

.headmenu .dropdown-menu {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-top: 0;
    margin: 0;
}


.headmenu .dropdown-menu a span {
    margin-right:10px;
}

.headmenu .dropdown-menu a .label.feature-label {
    margin-top:0px;
}

.header .nav-header {
    text-shadow: none;
    text-transform: none;
    font-weight: normal;
    color:var(--primary-font-color);
}

.headmenu .dropdown-menu::after {
    position: absolute;
    top: -6px;
    left: 45px;
    display: inline-block;
    border-right: 6px solid transparent;
    border-bottom: 6px solid white;
    border-left: 6px solid transparent;
    content: '';
}

.headmenu > li {
    display: inline-block;
    float: left;
    font-size: var(--base-font-size);
    position: relative;
    height: 50px;
}

.headmenu > li:first-child {
}

.headmenu > li.right {
    float: right;
}

.headmenu > li > a {
    min-width: 40px;
    position: relative;
    display: block;
    color: #fff;
    line-height: 50px;
    cursor: pointer;
    padding: 0 15px;
}

.headmenu > li > a.active,
.headmenu > li > a:hover {
    text-decoration: none;
    color: #fff;
    background: rgba(255, 255, 255, 0.2);
}

.headmenu > li > a .count {
    position: absolute;
    top: 5px;
    right: 10px;
    opacity: 0.5;
}

.headmenu > li > a:hover .count, .headmenu > li.open > a .count {
    opacity: 1;
}

.headmenu > li > a .headmenu-label {
    text-align: left;
    padding-left:10px;
}

.headmenu > li > a:hover .headmenu-label, .headmenu > li.open > a .headmenu-label {
    opacity: 1;
}

.head-icon {
    background-repeat: no-repeat;
}

.headmenu > li > a .head-icon {
    font-size: var(--base-font-size);
    line-height: 50px;
    padding-right:0;
}

.headmenu > li > a:hover .head-icon, .headmenu > li.open a .head-icon {
    opacity: 1;
}

.headmenu-label {

}

.leftmenu .nav li.dropdown {
    overflow: auto;
    display: flex;
    flex-flow: column;
    flex: 1;
    padding-bottom: 100px;
}

.leftmenu .nav li.dropdown > ul {
    display:block;
}





.leftmenu .nav-tabs.nav-stacked.collapsedMenu > li.dropdown ul.submenu li a {
    padding-left: 0;
}

.leftmenu .nav-tabs.nav-stacked.collapsedMenu .bigProjectSelector {
    display: block;
    font-weight: normal;
    font-size: var(--font-size-l);
    padding:0;
    line-height: 46px;
    height: 51px;
    width: 68px;
    border-bottom:1px solid var(--neutral);
    text-align: center;
    color: var(--project-selector-color);
    background: var(--project-selector-bg);
}

.leftmenu .nav-tabs.nav-stacked.collapsedMenu .bigProjectSelector:hover {
    background: var(--project-selector-hover-bg);
    color: var(--project-selector-hover-color);
}

.leftmenu .nav-tabs.nav-stacked.collapsedMenu .bigProjectSelector i.fa {
    font-size: var(--base-font-size);
    padding-left: 0;
    float: none;
    margin-top: 0;
}

.leftmenu .nav-tabs.nav-stacked.collapsedMenu .fixedMenuPoint {
    width:68px;
}






.leftmenu .projectselector::after {
    position: absolute;
    top: 30px;
    left: -9px;
    transform: rotate(-90deg);
    display: inline-block;
    border-right: 6px solid transparent;
    border-bottom: 6px solid white;
    border-left: 6px solid transparent;
    content: '';
}

.leftpanel .leftmenu .nav-tabs .projectselector {
}

.leftpanel .leftmenu .nav-tabs .projectselector .projectSelectorFooter {
    border-top:1px solid var(--main-border-color);
}

.leftpanel .leftmenu .nav-tabs .projectselector .projectSelectorFooter li a {
    display: block;
    padding-left: 15px;
}

.leftpanel .leftmenu .nav-tabs .projectselector  li a{
    color:var(--primary-font-color);
    font-size: var(--base-font-size);
    margin: 0;
    padding: 10px 20px;
}

.leftpanel .leftmenu .nav-tabs .projectselector li.active > a {
    background-image:none;
    background:var(--main-menu-link-active-bg);
    color:var(--main-menu-link-active-color);
    font-weight:bold;
}


.leftmenu .projectselector .head {
    padding:15px;
    color:var(--primary-font-color);
}

.leftmenu .projectselector .head .searchBar {
    float: right;
    padding-top: 5px;
}


.leftmenu .projectselector .head .title {
    font-size: var(--font-size-xxl);
}

/* Project Selection Tabs and container */
.leftpanel .leftmenu .nav-tabs .projectselector ul.tabs {

}
.leftpanel .leftmenu .nav-tabs .projectselector ul.tabs li a {
    padding: 0 15px;
    margin-left: 0px;
}

.leftpanel .leftmenu .nav-tabs .projectselector .ui-tabs-panel {
    padding:0;
}

.leftpanel .leftmenu .nav-tabs .projectselector .scrollingTab {
    padding:0;
    height:calc(100vh - 310px);
    overflow:auto;
}

/* Project Selection Lists */
.leftpanel .leftmenu .nav-tabs .projectselector ul.selectorList {
    padding-top:3px;
    padding-left: 0;
}


.leftpanel .leftmenu .nav-tabs .projectselector ul.selectorList.last {
    padding-left:0;
}

.leftpanel .leftmenu .nav-tabs .projectselector ul.selectorList li{
    display:block;
    padding:0;
    list-style-type: none;
    position:relative;
    cursor:pointer;
}

.leftpanel .leftmenu .nav-tabs .projectselector ul.selectorList li.nav-header {
    border-color:transparent;
    font-size: var(--base-font-size);
    text-transform: none;
    padding:5px 0 5px 15px;
    display:block;
    border-bottom:1px dotted var(--main-border-color);
}

.leftpanel .leftmenu .nav-tabs .projectselector ul.selectorList li.projectLineItem ul.projectGroup {
    clear:both;
    display:block;
    border-left:1px dotted var(--main-border-color);
}

.leftpanel .leftmenu .nav-tabs .projectselector ul.selectorList li.projectLineItem ul.projectGroup:hover {
    border-left:1px dotted var(--col-hover-bg);
}

.leftpanel .leftmenu .nav-tabs .projectselector ul.selectorList li.projectLineItem ul.projectGroup[class*=level-] {
    margin-left: 13px;
    padding-left: 5px;
    margin-top: 0px;
}

.leftpanel .leftmenu .nav-tabs .projectselector ul.selectorList li.projectLineItem ul.projectGroup.level-0,
.leftpanel .leftmenu .nav-tabs .projectselector ul.selectorList li.projectLineItem ul.projectGroup.level-1{
    margin-left: 18px;
}

.leftpanel .leftmenu .nav-tabs .projectselector ul.selectorList li.projectLineItem  ul.projectGroup.closed {
    display:none;
}

.leftpanel .leftmenu .nav-tabs .projectselector ul.selectorList li.projectLineItem  ul.projectGroup.open {
    display:inherit;
}




/* Individual project links */
.leftpanel .leftmenu .nav-tabs .projectselector ul.selectorList li.projectLineItem a {
    line-height:30px;
    min-height:50px;
    padding-left:15px;
    display: block;
}

.leftpanel .leftmenu .nav-tabs .projectselector ul.selectorList li.projectLineItem.hasSubtitle a .projectName {
    display: block;
    float: left;
    line-height: 12px;
    width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 30px;
    text-wrap: nowrap;
}

.leftpanel .leftmenu .nav-tabs .projectselector ul.selectorList li.projectLineItem.hasSubtitle a small {
    font-size:var(--font-size-xs);
    opacity:0.5;
}

.leftpanel .leftmenu .nav-tabs .projectselector ul.selectorList li.projectLineItem .projectAvatar {
    height:30px;
    width:30px;
    display:block;
    float:left;
    margin-right:10px;

    border-radius:5px;
}

.leftpanel .leftmenu .nav-tabs .projectselector ul.selectorList li .fas {

    padding-right:10px;
    padding-top:5px;
}



.leftpanel .leftmenu .nav-tabs .projectselector ul.selectorList li:hover >  a,
.leftpanel .leftmenu .nav-tabs .projectselector ul.selectorList li.activeChild >  a{
    background:var(--main-menu-link-active-bg);
    color:var(--main-menu-link-active-color);

}

.leftpanel .leftmenu .nav-tabs .projectselector ul.selectorList li:hover li a {
    border-radius: 0 0 0 10px;
}

.leftpanel .leftmenu .nav-tabs .projectselector ul.selectorList li.nav-header:hover {
    background:none;
}


.leftpanel .leftmenu .nav-tabs .projectselector ul.selectorList li.border {
    border-top:1px dotted var(--neutral);
}

.leftpanel .leftmenu .nav-tabs .projectselector ul.selectorList li.nav-header,
.leftpanel .leftmenu .nav-tabs .projectselector ul.selectorList li.fixedBottom {
    display:block;
}

.leftpanel .leftmenu .nav-tabs .projectselector ul.selectorList li.fixedBottom {
    position:absolute;
    width: 240px;
    bottom:0;
    display:block;

}

.leftpanel .leftmenu .nav-tabs .projectselector ul.selectorList.projectList li.projectLineItem .toggler {
    float: left;
    line-height: 20px;
    width: 18px;
    padding: 0;
    text-align: center;
    margin-left: 9px;
    margin-right: 3px;
    margin-top: 15px;
    border-radius: 5px;
    min-height: 20px;
}

.leftpanel .leftmenu .nav-tabs .projectselector ul.selectorList.projectList li.projectLineItem .projectGroup .toggler {
    margin-left:5px;
}

.leftpanel .leftmenu .nav-tabs .projectselector ul.selectorList.projectList li.projectLineItem .toggler:hover {
    background:var(--col-hover-bg);
}

.leftpanel .leftmenu .nav-tabs .projectselector ul.selectorList.projectList li.projectLineItem .favoriteIcon {
    float:right;
    line-height:30px;
    width:30px;
    color:var(--neutral) !important;
    padding-left:10px;
    margin-right:10px;
}







/* Header Navigation */

.header .userloggedinfo img {
    background-color: #fff;
    width: auto !important;
    max-height: 30px;
    max-width: 30px;
    border-radius: 50%;
    border: 1px solid white;
    margin-top:10px;
    margin-right: 5px;
}

.userloggedinfo {
    color: #fff;
    float: right;
    position:relative;
}

.userloggedinfo img {
    height: 40px;
    float: left;
}

.userloggedinfo .userinfo {
    float: left;
}

.userloggedinfo .userinfo a.profileHandler {
    color: #fff;
    padding:0 5px 0 10px;
    height:50px;
    line-height:50px;
    display: block;
    margin-right:15px;
}

.userloggedinfo .userinfo small {
    font-size: var(--base-font-size);
    opacity: 0.6;
    color: #fff;
    font-style: italic;
}

.userloggedinfo .dropdown-menu
{
    right:14px;
    left:auto;
}

.userloggedinfo .dropdown-menu::after{
    position: absolute;
    top: -6px;
    right: 28px;
    left:auto;
    display: inline-block;
    border-right: 6px solid transparent;
    border-bottom: 6px solid white;
    border-left: 6px solid transparent;
    content: '';
}

.appsDropdown .dropdown-menu {
    right:-10px;
    left:auto;
}

.appsDropdown .dropdown-menu:after {
    right:30px;
    top:-6px;
    left:auto;
}

#notificationsDropdown .scroll-wrapper {
    height:calc(100vh - 100px);
}

.notificationDropdown .dropdown-menu {
    right: -15px;
    left: auto;
    min-width:375px;
}

.notificationDropdown .dropdown-menu:after {
    right:30px;
    top:-6px;
    left:auto;
}

.notificationDropdown span.notificationCounter {
    position: absolute;
    top: 10px;
    right: 5px;
    height: 15px;
    width: 15px;
    background: var(--red);
    border-radius: 10px;
    line-height: 15px;
    text-align: center;
    font-size: var(--font-size-xs);
}

.userloggedinfo ul {
    list-style: none;
    margin-top:-1px;
}

.notificationDropdown .dropdown-menu li.new {
    border-left:5px solid var(--primary-color);
    font-weight:bold;
}

.notificationDropdown .dropdown-menu li {
    padding:10px;
    margin-bottom:1px;
    list-style-type:none;
}

.notificationDropdown .dropdown-menu li:hover {
    cursor:pointer;
    background:var(--main-menu-link-hover-bg);
}

.notificationDropdown .dropdown-menu li a:hover {
    cursor:pointer;
    background:var(--main-menu-link-hover-bg);
}

.notificationDropdown .dropdown-menu li .notificationProfileImage img {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    border: 1px solid var(--main-border-color);
    float:left;
    margin-right:10px;
    margin-top: 5px;
}

.notificationDropdown .dropdown-menu li .notificationTitle {
    font-size:var(--base-font-size);
    display:block;
}

.notificationDropdown .dropdown-menu li .notificationDate {
    font-size:var(--font-size-s);
    font-style: italic;
    color:var(--primary-font-color);
}

.notificationDropdown .notifcationTabs.active{
    font-weight:bold;

}



.userloggedinfo ul li,
.appsDropdown ul li{
    font-size: var(--base-font-size);
    line-height: normal;
    margin-bottom: 1px;
}

.userloggedinfo ul li a,
.appsDropdown ul li a{
    line-height: normal;
    padding:10px 20px;
}

.userloggedinfo ul li.border,
.appsDropdown ul li.border{
    border-top:1px solid var(--neutral);
}

.userloggedinfo ul li.nav-header,
.appsDropdown ul li.nav-header{
    padding:10px;
}

.userloggedinfo ul li a span,
.appsDropdown ul li a span{
    margin-right: 5px;
}

.userloggedinfo ul li a:hover,
.appsDropdown ul li a:hover{
    text-decoration: none;

}

.userloggedinfo a,
.appsDropdown a{
    color: var(--secondary-action-color);
}



.userloggedinfo a:hover {
    background: rgba(255, 255, 255, 0.2);
    color: #fff;
    text-decoration: none;
}

.submenuCaret {

}

.headmenu .dropdown-menu a span.notificationProfileImage {
    margin-right:0;
}


.scrollableMenu {
    height:calc(100% - 60px);
    overflow:auto;
}




/* Left Nav collapsed + + + + + + + + + + + + + + + + + */

.mainwrapper.menuclosed .barmenu {}

.mainwrapper.menuclosed .rightpanel {
    margin-left:68px;
}
.mainwrapper.menuclosed .header {
    margin-left:68px;
    width:calc(100% - 68px);
}
.mainwrapper.menuclosed .logo {
    display:none;
}
.mainwrapper.menuclosed .leftpanel {
    width:68px;
}

.mainwrapper.menuclosed .leftpanel .submenu {
    display:block !important;
}

.mainwrapper.menuclosed .leftpanel .submenuToggle {
    display:none;
}

.mainwrapper.menuclosed .leftpanel .leftmenu .nav-tabs.nav-stacked > li.dropdown a {
    font-size:0px;
    padding: 15px 0px 10px 0;
    width: 68px;
    text-align: center;
    color: var(--main-menu-link-color);
    border-left: 5px solid transparent;
    display: block;
    line-height:21px;
}

.mainwrapper.menuclosed .leftpanel .leftmenu .nav-tabs.nav-stacked > li.dropdown a span{
    font-size: var(--base-font-size);
}

.mainwrapper.menuclosed .leftmenu .nav-tabs.nav-stacked .bigProjectSelector i.fa {
    display:none;
}

.mainwrapper.menuclosed .leftmenu .nav-tabs.nav-stacked .bigProjectSelector {
    font-size: 0;
    text-align:center;
    height:51px;
    line-height:51px;
    border-bottom:1px solid var(--main-border-color);
}

.mainwrapper.menuclosed .leftmenu .nav-tabs.nav-stacked .bigProjectSelector .fa {
    font-size:var(--font-size-l);
}

.mainwrapper.menuclosed .leftmenu .nav-tabs.nav-stacked .bigProjectSelector > .projectAvatar {
    float:none;
    margin: 10px 0 0;
}

.mainwrapper.menuclosed .leftmenu .projectselector {
    left:73px;
}

.mainwrapper.menuclosed .leftmenu .nav-tabs.nav-stacked .dropdown ul li.active a {
    border-left: 5px solid var(--primary-color);
}

.mainwrapper.menuclosed .leftmenu .fixedMenuPoint {
    width:68px;
}

.mainwrapper.menuclosed .leftpanel .leftmenu .nav-tabs.nav-stacked > li.dropdown a span.feature-label {
    font-size:0;
    display:none;
}







/* Left Nav expanded + + + + + + + + + + + + + + + + + */

.mainwrapper.menuopen .barmenu {}
.mainwrapper.menuopen .rightpanel {
    margin-left:240px;
}
.mainwrapper.menuopen .header {
    margin-left:240px;
    width:calc(100% - 240px);
}
.mainwrapper.menuopen .logo {
    display:block;
}
.mainwrapper.menuopen .leftpanel {
    width:240px;
}

.mainwrapper.menuopen .leftpanel .submenu.closed {
    display:none;
}

.mainwrapper.menuopen .leftpanel .submenu.open {
    display:block;
}





/* New horizontal content tab nav */

.maincontentinner.tabs {
    height: 50px;
    margin-bottom: 7px;
    padding: 5px;
    padding-top:10px;
}


.maincontentinner.tabs ul {
    margin:0;
    padding:0;
}

.maincontentinner.tabs ul li {
    display:inline-block;
    float:left;
    padding:0 5px;
    margin-right:2px;
    background:var(--secondary-background);

}

.maincontentinner.tabs ul li.active {
    padding-bottom:20px;
    box-shadow: var(--regular-shadow);
    border-radius: 5px;
}

.maincontentinner.tabs ul li a {
    font-weight:bold;
    padding:5px 12px;
    border-radius:var(--element-radius);
    line-height:21px;
    display:block;
    transition: background-color 0.2s ease-in-out 0s;
    color:var(--primary-font-color);
}

.maincontentinner.tabs ul li.active a {
    color:var(--primary-color);
}

.maincontentinner.tabs ul li a:hover,
.maincontentinner.tabs ul li.active a:hover {
    background:var(--main-menu-link-active-bg);
    color:var(--primary-color);
}

.maincontentinner.tabs ul li a.active {

}

.maincontentinner.tabs .intro {
    display: inline-block;
    padding-top: 5px;
    padding-left: 10px;
    float: left;

}

.shepherd-element-attached-bottom.shepherd-element-attached-right.shepherd-target-attached-top.shepherd-target-attached-left .shepherd-content:before,
.shepherd-element-attached-bottom.shepherd-element-attached-left.shepherd-target-attached-top.shepherd-target-attached-right .shepherd-content:before,
.shepherd-element-attached-top.shepherd-element-attached-right.shepherd-target-attached-bottom.shepherd-target-attached-left .shepherd-content:before,
.shepherd-element-attached-top.shepherd-element-attached-left.shepherd-target-attached-bottom.shepherd-target-attached-right .shepherd-content:before {
  display: none; }

.shepherd-element, .shepherd-element:after, .shepherd-element:before, .shepherd-element *, .shepherd-element *:after, .shepherd-element *:before {
  box-sizing: border-box; }

.shepherd-element {
  position: absolute;
  display: none; }
  .shepherd-element.shepherd-open {
    display: block; }

.shepherd-element.shepherd-theme-arrows {
  max-width: 100%;
  max-height: 100%; }
  .shepherd-element.shepherd-theme-arrows .shepherd-content {
    border-radius: 5px;
    position: relative;
    font-family: inherit;
    background: #fff;
    color: #444;
    padding: 1em;
    font-size: 1.1em;
    line-height: 1.5em;
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
            filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2)); }
    .shepherd-element.shepherd-theme-arrows .shepherd-content:before {
      content: "";
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      border-color: transparent;
      border-width: 16px;
      border-style: solid;
      pointer-events: none; }
  .shepherd-element.shepherd-theme-arrows.shepherd-element-attached-bottom.shepherd-element-attached-center .shepherd-content {
    margin-bottom: 16px; }
    .shepherd-element.shepherd-theme-arrows.shepherd-element-attached-bottom.shepherd-element-attached-center .shepherd-content:before {
      top: 100%;
      left: 50%;
      margin-left: -16px;
      border-top-color: #fff; }
  .shepherd-element.shepherd-theme-arrows.shepherd-element-attached-top.shepherd-element-attached-center .shepherd-content {
    margin-top: 16px; }
    .shepherd-element.shepherd-theme-arrows.shepherd-element-attached-top.shepherd-element-attached-center .shepherd-content:before {
      bottom: 100%;
      left: 50%;
      margin-left: -16px;
      border-bottom-color: #fff; }
  .shepherd-element.shepherd-theme-arrows.shepherd-element-attached-right.shepherd-element-attached-middle .shepherd-content {
    margin-right: 16px; }
    .shepherd-element.shepherd-theme-arrows.shepherd-element-attached-right.shepherd-element-attached-middle .shepherd-content:before {
      left: 100%;
      top: 50%;
      margin-top: -16px;
      border-left-color: #fff; }
  .shepherd-element.shepherd-theme-arrows.shepherd-element-attached-left.shepherd-element-attached-middle .shepherd-content {
    margin-left: 16px; }
    .shepherd-element.shepherd-theme-arrows.shepherd-element-attached-left.shepherd-element-attached-middle .shepherd-content:before {
      right: 100%;
      top: 50%;
      margin-top: -16px;
      border-right-color: #fff; }
  .shepherd-element.shepherd-theme-arrows.shepherd-element-attached-left.shepherd-target-attached-center .shepherd-content {
    left: -32px; }
  .shepherd-element.shepherd-theme-arrows.shepherd-element-attached-right.shepherd-target-attached-center .shepherd-content {
    left: 32px; }
  .shepherd-element.shepherd-theme-arrows.shepherd-element-attached-top.shepherd-element-attached-left.shepherd-target-attached-middle .shepherd-content {
    margin-top: 16px; }
    .shepherd-element.shepherd-theme-arrows.shepherd-element-attached-top.shepherd-element-attached-left.shepherd-target-attached-middle .shepherd-content:before {
      bottom: 100%;
      left: 16px;
      border-bottom-color: #fff; }
  .shepherd-element.shepherd-theme-arrows.shepherd-element-attached-top.shepherd-element-attached-right.shepherd-target-attached-middle .shepherd-content {
    margin-top: 16px; }
    .shepherd-element.shepherd-theme-arrows.shepherd-element-attached-top.shepherd-element-attached-right.shepherd-target-attached-middle .shepherd-content:before {
      bottom: 100%;
      right: 16px;
      border-bottom-color: #fff; }
  .shepherd-element.shepherd-theme-arrows.shepherd-element-attached-bottom.shepherd-element-attached-left.shepherd-target-attached-middle .shepherd-content {
    margin-bottom: 16px; }
    .shepherd-element.shepherd-theme-arrows.shepherd-element-attached-bottom.shepherd-element-attached-left.shepherd-target-attached-middle .shepherd-content:before {
      top: 100%;
      left: 16px;
      border-top-color: #fff; }
  .shepherd-element.shepherd-theme-arrows.shepherd-element-attached-bottom.shepherd-element-attached-right.shepherd-target-attached-middle .shepherd-content {
    margin-bottom: 16px; }
    .shepherd-element.shepherd-theme-arrows.shepherd-element-attached-bottom.shepherd-element-attached-right.shepherd-target-attached-middle .shepherd-content:before {
      top: 100%;
      right: 16px;
      border-top-color: #fff; }
  .shepherd-element.shepherd-theme-arrows.shepherd-element-attached-top.shepherd-element-attached-left.shepherd-target-attached-bottom .shepherd-content {
    margin-top: 16px; }
    .shepherd-element.shepherd-theme-arrows.shepherd-element-attached-top.shepherd-element-attached-left.shepherd-target-attached-bottom .shepherd-content:before {
      bottom: 100%;
      left: 16px;
      border-bottom-color: #fff; }
  .shepherd-element.shepherd-theme-arrows.shepherd-element-attached-top.shepherd-element-attached-right.shepherd-target-attached-bottom .shepherd-content {
    margin-top: 16px; }
    .shepherd-element.shepherd-theme-arrows.shepherd-element-attached-top.shepherd-element-attached-right.shepherd-target-attached-bottom .shepherd-content:before {
      bottom: 100%;
      right: 16px;
      border-bottom-color: #fff; }
  .shepherd-element.shepherd-theme-arrows.shepherd-element-attached-bottom.shepherd-element-attached-left.shepherd-target-attached-top .shepherd-content {
    margin-bottom: 16px; }
    .shepherd-element.shepherd-theme-arrows.shepherd-element-attached-bottom.shepherd-element-attached-left.shepherd-target-attached-top .shepherd-content:before {
      top: 100%;
      left: 16px;
      border-top-color: #fff; }
  .shepherd-element.shepherd-theme-arrows.shepherd-element-attached-bottom.shepherd-element-attached-right.shepherd-target-attached-top .shepherd-content {
    margin-bottom: 16px; }
    .shepherd-element.shepherd-theme-arrows.shepherd-element-attached-bottom.shepherd-element-attached-right.shepherd-target-attached-top .shepherd-content:before {
      top: 100%;
      right: 16px;
      border-top-color: #fff; }
  .shepherd-element.shepherd-theme-arrows.shepherd-element-attached-top.shepherd-element-attached-right.shepherd-target-attached-left .shepherd-content {
    margin-right: 16px; }
    .shepherd-element.shepherd-theme-arrows.shepherd-element-attached-top.shepherd-element-attached-right.shepherd-target-attached-left .shepherd-content:before {
      top: 16px;
      left: 100%;
      border-left-color: #fff; }
  .shepherd-element.shepherd-theme-arrows.shepherd-element-attached-top.shepherd-element-attached-left.shepherd-target-attached-right .shepherd-content {
    margin-left: 16px; }
    .shepherd-element.shepherd-theme-arrows.shepherd-element-attached-top.shepherd-element-attached-left.shepherd-target-attached-right .shepherd-content:before {
      top: 16px;
      right: 100%;
      border-right-color: #fff; }
  .shepherd-element.shepherd-theme-arrows.shepherd-element-attached-bottom.shepherd-element-attached-right.shepherd-target-attached-left .shepherd-content {
    margin-right: 16px; }
    .shepherd-element.shepherd-theme-arrows.shepherd-element-attached-bottom.shepherd-element-attached-right.shepherd-target-attached-left .shepherd-content:before {
      bottom: 16px;
      left: 100%;
      border-left-color: #fff; }
  .shepherd-element.shepherd-theme-arrows.shepherd-element-attached-bottom.shepherd-element-attached-left.shepherd-target-attached-right .shepherd-content {
    margin-left: 16px; }
    .shepherd-element.shepherd-theme-arrows.shepherd-element-attached-bottom.shepherd-element-attached-left.shepherd-target-attached-right .shepherd-content:before {
      bottom: 16px;
      right: 100%;
      border-right-color: #fff; }

.shepherd-element.shepherd-theme-arrows.shepherd-element-attached-top.shepherd-element-attached-center.shepherd-has-title .shepherd-content:before, .shepherd-element.shepherd-theme-arrows.shepherd-element-attached-top.shepherd-element-attached-right.shepherd-target-attached-bottom.shepherd-has-title .shepherd-content:before, .shepherd-element.shepherd-theme-arrows.shepherd-element-attached-top.shepherd-element-attached-left.shepherd-target-attached-bottom.shepherd-has-title .shepherd-content:before {
  border-bottom-color: #eee; }

.shepherd-element.shepherd-theme-arrows.shepherd-has-title .shepherd-content header {
  background: #eee;
  padding: 1em; }
  .shepherd-element.shepherd-theme-arrows.shepherd-has-title .shepherd-content header a.shepherd-cancel-link {
    padding: 0;
    margin-bottom: 0; }

.shepherd-element.shepherd-theme-arrows.shepherd-has-cancel-link .shepherd-content header h3 {
  float: left; }

.shepherd-element.shepherd-theme-arrows .shepherd-content {
  padding: 0; }
  .shepherd-element.shepherd-theme-arrows .shepherd-content * {
    font-size: inherit; }
  .shepherd-element.shepherd-theme-arrows .shepherd-content header {
    *zoom: 1;
    border-radius: 5px 5px 0 0; }
    .shepherd-element.shepherd-theme-arrows .shepherd-content header:after {
      content: "";
      display: table;
      clear: both; }
    .shepherd-element.shepherd-theme-arrows .shepherd-content header h3 {
      margin: 0;
      line-height: 1;
      font-weight: normal; }
    .shepherd-element.shepherd-theme-arrows .shepherd-content header a.shepherd-cancel-link {
      float: right;
      text-decoration: none;
      font-size: 1.25em;
      line-height: .8em;
      font-weight: normal;
      color: rgba(0, 0, 0, 0.5);
      opacity: 0.25;
      position: relative;
      top: .1em;
      padding: .8em;
      margin-bottom: -.8em; }
      .shepherd-element.shepherd-theme-arrows .shepherd-content header a.shepherd-cancel-link:hover {
        opacity: 1; }
  .shepherd-element.shepherd-theme-arrows .shepherd-content .shepherd-text {
    padding: 1em; }
    .shepherd-element.shepherd-theme-arrows .shepherd-content .shepherd-text p {
      margin: 0 0 .5em 0;
      line-height: 1.3em; }
      .shepherd-element.shepherd-theme-arrows .shepherd-content .shepherd-text p:last-child {
        margin-bottom: 0; }
  .shepherd-element.shepherd-theme-arrows .shepherd-content footer {
    padding: 0 1em 1em; }
    .shepherd-element.shepherd-theme-arrows .shepherd-content footer .shepherd-buttons {
      text-align: right;
      list-style: none;
      padding: 0;
      margin: 0; }
      .shepherd-element.shepherd-theme-arrows .shepherd-content footer .shepherd-buttons li {
        display: inline;
        padding: 0;
        margin: 0; }
        .shepherd-element.shepherd-theme-arrows .shepherd-content footer .shepherd-buttons li .shepherd-button {
          display: inline-block;
          vertical-align: middle;
          *vertical-align: auto;
          *zoom: 1;
          *display: inline;
          border-radius: 3px;
          cursor: pointer;
          border: 0;
          margin: 0 .5em 0 0;
          font-family: inherit;
          text-transform: uppercase;
          letter-spacing: .1em;
          font-size: .8em;
          line-height: 1em;
          padding: .75em 2em;
          background: #3288e6;
          color: #fff; }
          .shepherd-element.shepherd-theme-arrows .shepherd-content footer .shepherd-buttons li .shepherd-button.shepherd-button-secondary {
            background: #eee;
            color: #888; }
        .shepherd-element.shepherd-theme-arrows .shepherd-content footer .shepherd-buttons li:last-child .shepherd-button {
          margin-right: 0; }


.shepherd-element.shepherd-theme-arrows {
    z-index: 100000;
}

.shepherd-element.shepherd-theme-arrows.shepherd-has-title .shepherd-content header,
.shepherd-cancel-link {
    color: var(--secondary-background);
}



.shepherd-element.shepherd-theme-arrows.shepherd-element-attached-top.shepherd-element-attached-center.shepherd-has-title .shepherd-content:before, .shepherd-element.shepherd-theme-arrows.shepherd-element-attached-top.shepherd-element-attached-right.shepherd-target-attached-bottom.shepherd-has-title .shepherd-content:before, .shepherd-element.shepherd-theme-arrows.shepherd-element-attached-top.shepherd-element-attached-left.shepherd-target-attached-bottom.shepherd-has-title .shepherd-content:before {
    border-bottom-color: var(--secondary-background);
}

.shepherd-element.shepherd-theme-arrows.shepherd-element-attached-top.shepherd-element-attached-center.shepherd-has-title .shepherd-content:before {
    border-bottom-color:var(--accent1);
}

.shepherd-element.shepherd-theme-arrows .shepherd-content {
    -webkit-filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.7));
    filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.7));
}


.shepherd-element.shepherd-theme-arrows .shepherd-content,
.shepherd-element.shepherd-theme-arrows.shepherd-has-title .shepherd-content header{
    border-radius:var(--box-radius);
}

.shepherd-element.shepherd-theme-arrows.shepherd-has-title .shepherd-content header {
    border-bottom-left-radius:0px;
    border-bottom-right-radius:0px;
}

.shepherd-button {
    border-radius:var(--element-radius);
}

.shepherd-element.shepherd-theme-arrows .shepherd-content header a.shepherd-cancel-link {
    color: var(--secondary-background);
}

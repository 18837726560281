
.nyroModalCont, .nyroModalBg {
    z-index: 100;
    overflow-x: hidden !important;
    padding: 34px 40px 20px 35px
}

.nyroModalBg {
    position: fixed;
    overflow: auto;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(3px);
}
.nmReposition {
    position: absolute;
}

.nyroModalCloseButton:hover {

}

.nyroModalLink, .nyroModalDom, .nyroModalForm, .nyroModalFormFile {

}

.nyroModalCloseButton {

    font-size: var(--font-size-xxl);
    text-indent: 0;
    color: #aaa !important;
    border-radius:5px;
    height: 25px;
    line-height: 26px;
    right: 26px;
    text-align: center;
    top: 31px;
    width: 25px;
    z-index: 1000010;
}

.nyroModalCloseButton:hover {
    text-decoration: none;
    color: var(--primary-font-color)  !important;
    background-color:var(--col-hover-bg);
}

.nyroModalTitle {
    top: -26px;
    left: 0;
    margin: 0;
    font-size: 1.1em;
    color: #ddd;
}
.nyroModalCont {
    position: relative;
    /*box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);*/
    background: var(--secondary-background);
    border-radius:20px;
    margin-bottom:50px;
}
.nyroModalCont iframe {
    width: 600px;
    height: 400px;
}
.nyroModalError {
    border: 4px solid var(--red);
    color: var(--red);
    width: 250px;
    height: 250px;
}
.nyroModalError div {
    min-width: 0;
    min-height: 0;
    padding: 10px;
}
.nyroModalLink, .nyroModalDom, .nyroModalForm, .nyroModalFormFile {
    position: relative;
    min-height: 100px;
    min-width: 250px;
}
.nyroModalImage, .nyroModalSwf, .nyroModalIframe, .nyroModalIframeForm {
    position: relative;
    overflow: hidden;
}
.nyroModalImage img {
    vertical-align: top;
}
.nyroModalHidden {
    left: -9999em;
    top: -9999em;
}
.nyroModalLoad {
    position: absolute;
    width: 100px;
    height: 100px;
    background: transparent url(../../images/svg/loading-animation.svg) no-repeat center;
    padding: 0;
    background-size: 100px 100px;
}
.nyroModalPrev, .nyroModalNext {
    outline: none;
    position: absolute;
    top: 0;
    height: 60%;
    width: 150px;
    min-height: 50px;
    max-height: 300px;
    cursor: pointer;
    text-indent: -9999em;
    background: transparent url('data:image/gif;base64,AAAA') left 20% no-repeat;
}
.nyroModalImage .nyroModalPrev, .nyroModalImage .nyroModalNext {
    height: 100%;
    width: 40%;
    max-height: none;
}
.nyroModalPrev {
    left: 0;
}
.nyroModalPrev:hover {
}
.nyroModalNext {
    right: 0;
}
.nyroModalNext:hover {
}

.nyroModalCont textarea,
.nyroModalCont input[type="text"],
.nyroModalCont select {
    /*width:100%;*/
}

.nyroModalCont select {
    max-width:100%;
    height: 41px;
}

.nyroModalLink, .nyroModalDom, .nyroModalForm, .nyroModalFormFile {

}

.nyroModalCont h1 {
    font-size:var(--font-size-xxl);
    color:var(--primary-font-color);
    margin-bottom:20px;
}

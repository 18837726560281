/*

Mobile Stylesheet
Available Breakpoints

min-width: 375px lowest tested resolution
min-width: 576px
min-width: 768px //Not used
min-width: 992px //Not used
min-width: 1200px
 */


@media (max-width:575px) {

    .leftpanel .leftmenu .nav-tabs .projectselector .projectSelectorTabs .scrollingTab {
        height:auto;
        overflow:scroll;
    }

    .progressWrapper .step {
        display:none;
    }

    .progressWrapper .step.current {
        display:inherit;

    }

    .maincontentinner {
        overflow:auto;
    }

    .tabbedwidget ul li {
        width:100%;
    }

    .leftmenu .projectselector .projectSelectorTabs .tabs li{
        width:auto;
    }

    .barmenu,
    .headerCTA{
        display:none;
    }



    .col-md-4 > .pull-right,
    .col-md-6 > .pull-right{
        width:100%;
    }

    .dropdown-menu {
        width:100%;
    }

    .fc-header {
        margin-top:50px;
    }

    .maincontentinner .btn,
    .maincontentinner .btn-group{

        margin-bottom:5px;
    }

    .maincontentinner .simpleButtons .btn,
    .maincontentinner .simpleButtons .btn-group{
        width:auto;
    }

    .maincontentinner .simpleButtons {
        width:auto;
    }


    .col-md-4 > .pull-right,
    .col-md-5 > .pull-right,
    .col-md-6 > .pull-right {
        width:100%;
    }



    .currentSprint {
        margin:0px;
    }



    .profileHandler .username {
        display:none;
    }



    .headmenu > li > a {
        padding: 15px 10px 9px;
    }

    .maincontent {
        margin-top:0px;
        padding: 10px 10px;
    }

    /* buttons */
    .glyphicons li {
        width: 50%;
    }

    .iconsweetslist li {
        width: 50%;
    }

    /* forms */
    #elm1_toolbargroup {
        width: 560px;
    }

    /* media */
    .mediamgr_category, .mediamgr_content {
        margin-right: 0;
    }

    .mediamgr .mediamgr_right {
        position: static;
        width: auto;
    }

    .mediamgr .mediamgr_rightinner {
        padding-left: 0;
    }

    .mediamgr_category ul li.right {
        float: right;
        border-top: 0;
        margin-top: 0;
    }
    .hidden-gt-sm {
        display:block!important;
    }

    .loginpage .header {
        margin-left: 0 !important;
        /* margin-bottom: 10px; */

        width: 100%;
    }

    .headmenu > li > a {
        padding: 15px 15px 9px;
    }

    .userloggedinfo .userinfo small {

    }

    .userloggedinfo {

    }

    .userloggedinfo .userinfo {

    }

    /* media */
    .mediaWrapper {
        width: 400px;
    }

    /* table */
    .dataTable th, .dataTable td {
        padding: 5px;
    }

    .dataTable th:nth-child(2), .dataTable td:nth-child(2) {

    }

    .dataTables_paginate {
        position: relative;
        margin: -25px 0 0 20px;
    }

    .dataTables_info {
        height: 50px;
    }

    #dyntable2_info {
        height: auto;
    }

    .dataTables_filter {
        position: relative;
        margin: -50px 0 20px 20px;
    }

    .dataTables_length {
        height: 70px;
    }

    #dyntable2_wrapper .dataTables_filter {
        margin: 0;
    }
    .hidden-gt-sm {
        display:block!important;
    }

    .headmenu > li > a {
        padding: 15px 20px 9px;
    }

    .headmenu > li.right {
        padding-bottom: 13px;
    }

    .pageheader {
        padding: 5px 15px;
        min-height: 50px;
    }

    .searchbar {
        position: static;
        margin-bottom: 5px;
    }

    .searchbar input {
        width: 100%;
        -moz-box-sizing: border-box;
        height: auto;
        background-position: 410px 10px;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }

    /* buttons */
    .tabbable > .nav-tabs > li > a {
        padding: 10px;
    }

    /* forms */
    .wizard .hormenu li {
        float: none;
        display: block;
        width: auto;
        border: 2px solid #0866c6;
        margin-bottom: 5px;
    }

    .wizard .hormenu li a {
        border: 0;
    }

    .wizard .hormenu li a.done {
        border: 0;
    }

    .wizard .hormenu {
        margin-bottom: 20px;
    }

    .wizard-inverse .hormenu li {
        border-color: #333;
    }

    .wizard .tabbedmenu {
        height: auto;
        padding: 10px;
    }

    .wizard .tabbedmenu li {
        display: block;
        margin-bottom: 10px;
    }

    .wizard .tabbedmenu li:last-child {
        margin-bottom: 0;
    }

    .wizard .tabbedmenu li a {
        padding: 10px;
    }

    #elm1_toolbargroup {
        width: 395px;
    }

    /* media */
    .mediamgr_menu li.right {
        float: none;
        margin-top: 10px;
    }

    .mediamgr_category ul li.right {
        float: none;
        text-align: right;
        display: block;
        border-top: 1px dashed #ddd;
        margin-top: 10px;
    }

    .mediamgr .mediamgr_right {
        top: 110px;
    }

    .mediamgr_menu li.filesearch {
        margin: 10px 0;
        width: 100%;
    }

    .mediamgr_menu form input.filekeyword {
        width: 100%;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        height: auto;
        margin-bottom: 0;
    }

    .mediamgr_menu li.right {
        margin-top: 0;
    }

    .mediaWrapper {
        width: 280px;
    }

    /* messages */
    .messageleft {
        width: auto;
        float: none;
        height: auto;
    }

    .messageright {
        margin-left: 0;
        display: none;
        height: auto;
        border-left: 1px solid #0866c6;
    }

    .messagesearch input {
        background-position: 375px 9px;
    }

    .msglist {
        height: auto;
        border-bottom-width: 1px;
    }

    .messageview {
        height: auto;
    }

    /* calendar */
    .fc-button {
        padding: 0 5px;
    }

    .fc-header-title {
        margin-top: 3px;
    }

    /* invoice */
    .amountdue h1 {
        padding: 10px 25px;
    }

    .amountdue .btn {
        width: 225px;
    }
    .hidden-gt-sm {
        display:block!important;
    }

    .hidden-gt-sm {
        display: block !important;
    }

    .dataTables_filter {
        display: none;
    }

    .modalTextArea {
        height: 75px;
    }

    .pagetitle {
        padding-left: 0px;
        margin-left: 0px;
    }

    .loginpage .pagetitle {
        padding-left: 0px;
    }

    .row-fluid .column {
        width: 100%;
        clear: both;
        height: auto;
    }

    .contentInner {
        height: auto !important;
    }

    .pagetitle h1 {
        font-size: 18px;
    }

    .pageheader {
        padding: 5px 15px;
        min-height: 50px;
    }

    .header {
        height: 60px;
    }

    .headmenu > li {
        padding: unset;
        width: 40px;
    }

    .headmenu > li > a {
        padding: 0 5px;
        line-height:60px;
        text-align:center;

    }

    .userloggedinfo .userinfo a.profileHandler {
        line-height:60px;
        height:60px;
        margin-right: 0px;
    }

    .header .userloggedinfo img {
        margin-top:15px;
    }

    .headmenu > li > a .head-icon {
        width: 30px;
        height: 30px;
        background-size: cover;
    }

    .headmenu > li > a .headmenu-label {
        display: none;
    }

    .leftmenu .nav-tabs.nav-stacked li a {
        font-size: 13px;
    }

    .leftmenu .nav-tabs.nav-stacked .dropdown ul li a {
        font-size: 12px;
    }


    .header .logo {
        padding-top: 0;
    }


    .searchbar input {
        background-position: 285px 10px;
    }

    /* buttons */
    .tabbable > .nav-tabs > li {
        overflow: hidden;
        font-size: 11px;
    }

    .tabbable > .nav-tabs > li a {
        padding: 10px 5px;
    }

    .glyphicons li {
        width: auto;
        float: none;
    }

    .fontawesomeicons .span3 {
        width: auto;
        float: none;
    }

    .iconsweetslist li {
        width: auto;
        float: none;
    }

    /* forms */
    .dualselect select {
        width: 38%;
    }

    .chzn-container {
        width: 270px !important;
    }

    .chzn-drop {
        width: 268px !important;
    }

    .chzn-search input {
        width: 220px !important;
    }

    .tagsinput {
        width: 260px !important;
    }

    #elm1_toolbargroup {
        width: 285px;
    }

    /* elements */
    .navbar .brand {
        display: none;
    }

    /* hidden for demo purposes only */
    /* boxes */
    .slide_img, .entry_img {
        float: none;
        margin-bottom: 10px;
    }

    .slide_content, .entry_content {
        margin-left: 0;
    }

    /* media */
    .mediamgr_category ul li {
        float: none;
        display: block;
        margin: 0;
    }

    .mediamgr_menu li {
        margin-bottom: 10px;
    }

    .mediamgr_menu li.newfilebtn {
        margin-left: 10px;
    }

    .mediaWrapper {
        width: 240px;
    }

    .imgpreview {
        width: 209px;
    }

    /* messages */
    .messagemenu ul {
        height: 45px;
    }

    .messagemenu ul li a {
        padding: 12px 10px;
    }

    /* calendar */

    .fc-header-title h2 {
        margin-top: -50px;
    }

    .fc-button-month {
        margin-left: -50px;
    }

    /* error */
    .errortitle h4 {
        font-size: 20px;
    }

    .errortitle span {
        font-size: 50px;
    }

    .errortitle span:first-child {
        margin-left: 0;
    }

    /* bootstrap */
    .tabbable > .tab-content {
        padding: 15px;
    }

    .btn-circle {
        width: 6px;
    }

    .btn-circle i {
        margin-left: -3px;
    }

    .input-append .btn, .input-prepend .btn {
        padding-left: 8px;
        padding-right: 8px;
        font-size: 12px;
    }

    /* footer */
    .footer .footer-left {
        float: none;
        text-align: center;
    }

    .footer .footer-right {
        float: none;
        text-align: center;
    }

    .column-filter-widgets {
        display: none;
    }
    .hidden-gt-sm {
        display:block!important;
    }

    .hidden-sm {
        display:none;
    }

    body {
        line-height: 18px;
    }

    .leftpanel {
        width: 68px;
    }

    .header .logo {
        width: 240px;
    }

    .headmenu > li > a {

    }

    .searchbar input {
        background-position: 245px 10px;
    }

    /* dashboard */
    .shortcuts li a {
        width: 125px;
    }

    .commentlist li img {
        width: 40px;
    }

    .commentlist li .comment-info {
        margin-left: 55px;
    }

    .commentlist li .comment-info h4 {
        font-size: 14px;
    }

    /* buttons */
    .buttons-icons li {
        width: 50px;
        overflow: hidden;
    }

    .buttons-icons li a {
        width: 150px;
    }

    /* forms */
    .stdform .input-append input, .stdform .input-prepend input {
        width: 100px !important;
    }

    #elm1_toolbargroup {
        width: 240px;
    }

    /* media */
    .mediamgr_menu li.newfoldbtn {
        margin-left: 0;
        clear: left;
    }

    /* calendar */
    .fc-button-month {
        margin-left: -90px;
    }

    .fc-header-title h2 {
        margin-left: -175px;
    }
    .hidden-gt-sm {
        display:block!important;
    }

    .column {
        /*A little smaller so you can see the column to the right indicating there is more*/
        width:85% !important;
    }

    .ui-tabs-nav > li a {
        padding:10px 6px;
    }

    .hidden-gt-sm {
        display:block !important;
    }

    #ideaMason .ticketBox {
        width:100%;
    }

    .headerinner {
        width: 100%;
        margin-left: 0 !important;

    }

    .stickyColumn {
        position:relative;
    }

    .userinfo .fa-caret-down {
        display:none;
    }
}

@media (min-width: 576px) and (max-width:1200px) {

    .progressWrapper .step {
        display:none;
    }

    .progressWrapper .step.current {
        display:inherit;

    }


    .leftmenu .projectselector .projectSelectorTabs .tabs li{
        width:auto;
    }

    .barmenu,
    .headerCTA{
        display:none;
    }

    .hidden-sm {
        display:none;
    }

    .hidden-phone {
        display: none!important;
    }

    .maincontentinner {
        overflow:auto;
    }

    .tabbedwidget ul li {
        width:100%;
    }

    .col-md-4 > .pull-right,
    .col-md-5 > .pull-right,
    .col-md-6 > .pull-right{
        width:100%;
    }

    .dropdown-menu {
        width:100%;
    }

    .fc-header {
        margin-top:50px;
    }

    .maincontentinner .btn,
    .maincontentinner .btn-group{
        width:100%;
        margin-bottom:5px;
    }

    .currentSprint {
        margin:0px;
    }

    .pageheader {
        padding: 5px 15px;
        min-height: 50px;
    }

    .leftpanel a.barmenu {
        position: fixed;
        left: 0px;
        top: 5px;
        margin-left:0px;
        padding-left:15px;
        width:50px;
    }

    .leftpanel a.barmenu.open {
        left:240px;
        background:var(--primary-color);
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        box-shadow:var(--regular-shadow);

    }

    .leftpanel {
        position:absolute;
    }

    .header {


    }

    .rightpanel {
        width:calc(100% - 63px);

    }

    .maincontent {
        margin-top:0px;
        padding: 10px 10px;
    }

    .col-sm {
        -webkit-flex-basis: 0;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%
    }

    .col-sm-auto {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .col-sm-1 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 8.333333%;
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%
    }

    .col-sm-2 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 16.666667%;
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%
    }

    .col-sm-3 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 25%;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-sm-4 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 33.333333%;
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%
    }

    .col-sm-5 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 41.666667%;
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%
    }

    .col-sm-6 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-sm-7 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 58.333333%;
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%
    }

    .col-sm-8 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 66.666667%;
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%
    }

    .col-sm-9 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 75%;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-sm-10 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 83.333333%;
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%
    }

    .col-sm-11 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 91.666667%;
        -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%
    }

    .col-sm-12 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }

    .pull-sm-0 {
        right: auto
    }

    .pull-sm-1 {
        right: 8.333333%
    }

    .pull-sm-2 {
        right: 16.666667%
    }

    .pull-sm-3 {
        right: 25%
    }

    .pull-sm-4 {
        right: 33.333333%
    }

    .pull-sm-5 {
        right: 41.666667%
    }

    .pull-sm-6 {
        right: 50%
    }

    .pull-sm-7 {
        right: 58.333333%
    }

    .pull-sm-8 {
        right: 66.666667%
    }

    .pull-sm-9 {
        right: 75%
    }

    .pull-sm-10 {
        right: 83.333333%
    }

    .pull-sm-11 {
        right: 91.666667%
    }

    .pull-sm-12 {
        right: 100%
    }

    .push-sm-0 {
        left: auto
    }

    .push-sm-1 {
        left: 8.333333%
    }

    .push-sm-2 {
        left: 16.666667%
    }

    .push-sm-3 {
        left: 25%
    }

    .push-sm-4 {
        left: 33.333333%
    }

    .push-sm-5 {
        left: 41.666667%
    }

    .push-sm-6 {
        left: 50%
    }

    .push-sm-7 {
        left: 58.333333%
    }

    .push-sm-8 {
        left: 66.666667%
    }

    .push-sm-9 {
        left: 75%
    }

    .push-sm-10 {
        left: 83.333333%
    }

    .push-sm-11 {
        left: 91.666667%
    }

    .push-sm-12 {
        left: 100%
    }

    .offset-sm-0 {
        margin-left: 0
    }

    .offset-sm-1 {
        margin-left: 8.333333%
    }

    .offset-sm-2 {
        margin-left: 16.666667%
    }

    .offset-sm-3 {
        margin-left: 25%
    }

    .offset-sm-4 {
        margin-left: 33.333333%
    }

    .offset-sm-5 {
        margin-left: 41.666667%
    }

    .offset-sm-6 {
        margin-left: 50%
    }

    .offset-sm-7 {
        margin-left: 58.333333%
    }

    .offset-sm-8 {
        margin-left: 66.666667%
    }

    .offset-sm-9 {
        margin-left: 75%
    }

    .offset-sm-10 {
        margin-left: 83.333333%
    }

    .offset-sm-11 {
        margin-left: 91.666667%
    }

    .container {
        padding-right: 15px;
        padding-left: 15px
    }

    .container {
        width: 540px;
        max-width: 100%
    }

    .container-fluid {
        padding-right: 15px;
        padding-left: 15px
    }

    .row {
        margin-right: -15px;
        margin-left: -15px
    }

    .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9 {
        padding-right: 15px;
        padding-left: 15px
    }

    .nyroModalCont {
        width: 90% !important;
        left: 2.5% !important;
    }

    .maincontentinner {

    }

    .tabbedwidget .btn-group {
        display: none;
    }

    .ui-tabs-nav > li a {
        padding: 10px 6px;
    }

    .pageicon {
    }

    /* forms */
    #elm1_toolbargroup {
        width: 680px;
    }

    /* messages */
    .messageview .subject {
        padding-right: 110px;
        padding-left: 10px;
    }

    .msgauthor, .msgbody {
        padding: 10px;
    }

    .msgauthor .authorinfo h5 span {
        margin-left: 0;
        display: block;
    }

    /* media */
    .mediaWrapper {
        width: 500px;
    }

    .row-fluid .span5.imginfo {
        width: auto;
        float: none;
        margin-bottom: 20px;
    }

    .row-fluid .span7.imgdetails {
        width: auto;
        margin: 0;
        float: none;
    }

    .loginpanel {
        width: 80%;
    }

    #dashboard-left, #dashboard-right {
        width: 100%;
        margin: 0;
    }

    /* calendar */
    .ui-datepicker-calendar td a {
        padding: 10px;
        font-size: 12px;
    }

    /* buttons */
    .fontawesomeicons .span3 {
        width: 48%;
        display: inline-block;
    }

    .iconsweetslist li {
        width: 33.3333%;
    }

    /* forms */
    .stdform label {
        float: none;
        width: auto;
        text-align: left;
        margin-bottom: 5px;
    }

    .stdform div.par .controls,
    .stdform .stdformbutton, .stdform small.desc,
    .stdform2 span.field, .stdform2 div.field,
    .stdform span.field, .stdform div.field,
    .stdform .formwrapper, .dualselect {
        margin-left: 0;
    }

    .input-append .add-on, .input-prepend .add-on {
        height: 20px;
    }

    .stdform input {
        padding: 4px 5px;
    }

    .stdform2 p, .stdform2 div.par {
        background: none;
    }

    .stepContainer p {
        margin: 10px 15px;
    }

    #wiz1step2_1 p {
        margin: 10px 0;
    }

    .tabbedwizard .stepContainer {
        padding: 30px 15px;
    }

    #elm1_toolbargroup {
        width: 640px;
    }

    /* boxes */
    .bx-wrapper {
        margin-bottom: 20px;
    }

    .slide_img {
        float: left;
        width: 100px;
    }

    .slide_content {
        margin-left: 120px;
    }

    .entry_img {
        float: left;
    }

    .entry_content {
        margin-left: 120px;
    }

    /* media */
    .mediamgr_menu li.right {
        float: none;
        margin-top: 10px;
    }

    .mediamgr_category ul li.right {
        float: none;
        text-align: right;
        display: block;
        border-top: 1px dashed #ddd;
        margin-top: 10px;
    }

    .mediamgr .mediamgr_right {
        top: 110px;
    }

    /* messages */
    .messageleft {
        width: 241px;
    }

    .messageright {
        margin-left: 241px;
    }

    /* bootstrap */
    .btn {
        padding: 4px 12px;
    }

    .ticketBox {
        width: 100% !important;
    }
    .hidden-gt-sm {
        display:block!important;
    }

    .headerinner {
        width: 100%;
        margin-left: 0 !important;
    }

    .stickyColumn {
        position:relative;
    }

}

@media (min-width: 1201px) {
    .col-md {
        -webkit-flex-basis: 0;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%
    }

    .col-md-auto {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .col-md-1 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 8.333333%;
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%
    }

    .col-md-2 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 16.666667%;
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%
    }

    .col-md-3 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 25%;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-md-4 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 33.333333%;
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%
    }

    .col-md-5 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 41.666667%;
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%
    }

    .col-md-6 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-md-7 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 58.333333%;
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%
    }

    .col-md-8 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 66.666667%;
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%
    }

    .col-md-9 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 75%;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-md-10 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 83.333333%;
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%
    }

    .col-md-11 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 91.666667%;
        -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%
    }

    .col-md-12 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }

    .pull-md-0 {
        right: auto
    }

    .pull-md-1 {
        right: 8.333333%
    }

    .pull-md-2 {
        right: 16.666667%
    }

    .pull-md-3 {
        right: 25%
    }

    .pull-md-4 {
        right: 33.333333%
    }

    .pull-md-5 {
        right: 41.666667%
    }

    .pull-md-6 {
        right: 50%
    }

    .pull-md-7 {
        right: 58.333333%
    }

    .pull-md-8 {
        right: 66.666667%
    }

    .pull-md-9 {
        right: 75%
    }

    .pull-md-10 {
        right: 83.333333%
    }

    .pull-md-11 {
        right: 91.666667%
    }

    .pull-md-12 {
        right: 100%
    }

    .push-md-0 {
        left: auto
    }

    .push-md-1 {
        left: 8.333333%
    }

    .push-md-2 {
        left: 16.666667%
    }

    .push-md-3 {
        left: 25%
    }

    .push-md-4 {
        left: 33.333333%
    }

    .push-md-5 {
        left: 41.666667%
    }

    .push-md-6 {
        left: 50%
    }

    .push-md-7 {
        left: 58.333333%
    }

    .push-md-8 {
        left: 66.666667%
    }

    .push-md-9 {
        left: 75%
    }

    .push-md-10 {
        left: 83.333333%
    }

    .push-md-11 {
        left: 91.666667%
    }

    .push-md-12 {
        left: 100%
    }

    .offset-md-0 {
        margin-left: 0
    }

    .offset-md-1 {
        margin-left: 8.333333%
    }

    .offset-md-2 {
        margin-left: 16.666667%
    }

    .offset-md-3 {
        margin-left: 25%
    }

    .offset-md-4 {
        margin-left: 33.333333%
    }

    .offset-md-5 {
        margin-left: 41.666667%
    }

    .offset-md-6 {
        margin-left: 50%
    }

    .offset-md-7 {
        margin-left: 58.333333%
    }

    .offset-md-8 {
        margin-left: 66.666667%
    }

    .offset-md-9 {
        margin-left: 75%
    }

    .offset-md-10 {
        margin-left: 83.333333%
    }

    .offset-md-11 {
        margin-left: 91.666667%
    }

    .container {
        padding-right: 15px;
        padding-left: 15px
    }

    .col-lg {
        -webkit-flex-basis: 0;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%
    }

    .col-lg-auto {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .col-lg-1 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 8.333333%;
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%
    }

    .col-lg-2 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 16.666667%;
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%
    }

    .col-lg-3 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 25%;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-lg-4 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 33.333333%;
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%
    }

    .col-lg-5 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 41.666667%;
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%
    }

    .col-lg-6 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-lg-7 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 58.333333%;
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%
    }

    .col-lg-8 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 66.666667%;
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%
    }

    .col-lg-9 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 75%;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-lg-10 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 83.333333%;
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%
    }

    .col-lg-11 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 91.666667%;
        -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%
    }

    .col-lg-12 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }

    .pull-lg-0 {
        right: auto
    }

    .pull-lg-1 {
        right: 8.333333%
    }

    .pull-lg-2 {
        right: 16.666667%
    }

    .pull-lg-3 {
        right: 25%
    }

    .pull-lg-4 {
        right: 33.333333%
    }

    .pull-lg-5 {
        right: 41.666667%
    }

    .pull-lg-6 {
        right: 50%
    }

    .pull-lg-7 {
        right: 58.333333%
    }

    .pull-lg-8 {
        right: 66.666667%
    }

    .pull-lg-9 {
        right: 75%
    }

    .pull-lg-10 {
        right: 83.333333%
    }

    .pull-lg-11 {
        right: 91.666667%
    }

    .pull-lg-12 {
        right: 100%
    }

    .push-lg-0 {
        left: auto
    }

    .push-lg-1 {
        left: 8.333333%
    }

    .push-lg-2 {
        left: 16.666667%
    }

    .push-lg-3 {
        left: 25%
    }

    .push-lg-4 {
        left: 33.333333%
    }

    .push-lg-5 {
        left: 41.666667%
    }

    .push-lg-6 {
        left: 50%
    }

    .push-lg-7 {
        left: 58.333333%
    }

    .push-lg-8 {
        left: 66.666667%
    }

    .push-lg-9 {
        left: 75%
    }

    .push-lg-10 {
        left: 83.333333%
    }

    .push-lg-11 {
        left: 91.666667%
    }

    .push-lg-12 {
        left: 100%
    }

    .offset-lg-0 {
        margin-left: 0
    }

    .offset-lg-1 {
        margin-left: 8.333333%
    }

    .offset-lg-2 {
        margin-left: 16.666667%
    }

    .offset-lg-3 {
        margin-left: 25%
    }

    .offset-lg-4 {
        margin-left: 33.333333%
    }

    .offset-lg-5 {
        margin-left: 41.666667%
    }

    .offset-lg-6 {
        margin-left: 50%
    }

    .offset-lg-7 {
        margin-left: 58.333333%
    }

    .offset-lg-8 {
        margin-left: 66.666667%
    }

    .offset-lg-9 {
        margin-left: 75%
    }

    .offset-lg-10 {
        margin-left: 83.333333%
    }

    .offset-lg-11 {
        margin-left: 91.666667%
    }

    .col-xl {
        -webkit-flex-basis: 0;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%
    }

    .col-xl-auto {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .col-xl-1 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 8.333333%;
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%
    }

    .col-xl-2 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 16.666667%;
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%
    }

    .col-xl-3 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 25%;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-xl-4 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 33.333333%;
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%
    }

    .col-xl-5 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 41.666667%;
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%
    }

    .col-xl-6 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-xl-7 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 58.333333%;
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%
    }

    .col-xl-8 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 66.666667%;
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%
    }

    .col-xl-9 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 75%;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-xl-10 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 83.333333%;
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%
    }

    .col-xl-11 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 91.666667%;
        -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%
    }

    .col-xl-12 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }

    .pull-xl-0 {
        right: auto
    }

    .pull-xl-1 {
        right: 8.333333%
    }

    .pull-xl-2 {
        right: 16.666667%
    }

    .pull-xl-3 {
        right: 25%
    }

    .pull-xl-4 {
        right: 33.333333%
    }

    .pull-xl-5 {
        right: 41.666667%
    }

    .pull-xl-6 {
        right: 50%
    }

    .pull-xl-7 {
        right: 58.333333%
    }

    .pull-xl-8 {
        right: 66.666667%
    }

    .pull-xl-9 {
        right: 75%
    }

    .pull-xl-10 {
        right: 83.333333%
    }

    .pull-xl-11 {
        right: 91.666667%
    }

    .pull-xl-12 {
        right: 100%
    }

    .push-xl-0 {
        left: auto
    }

    .push-xl-1 {
        left: 8.333333%
    }

    .push-xl-2 {
        left: 16.666667%
    }

    .push-xl-3 {
        left: 25%
    }

    .push-xl-4 {
        left: 33.333333%
    }

    .push-xl-5 {
        left: 41.666667%
    }

    .push-xl-6 {
        left: 50%
    }

    .push-xl-7 {
        left: 58.333333%
    }

    .push-xl-8 {
        left: 66.666667%
    }

    .push-xl-9 {
        left: 75%
    }

    .push-xl-10 {
        left: 83.333333%
    }

    .push-xl-11 {
        left: 91.666667%
    }

    .push-xl-12 {
        left: 100%
    }

    .offset-xl-0 {
        margin-left: 0
    }

    .offset-xl-1 {
        margin-left: 8.333333%
    }

    .offset-xl-2 {
        margin-left: 16.666667%
    }

    .offset-xl-3 {
        margin-left: 25%
    }

    .offset-xl-4 {
        margin-left: 33.333333%
    }

    .offset-xl-5 {
        margin-left: 41.666667%
    }

    .offset-xl-6 {
        margin-left: 50%
    }

    .offset-xl-7 {
        margin-left: 58.333333%
    }

    .offset-xl-8 {
        margin-left: 66.666667%
    }

    .offset-xl-9 {
        margin-left: 75%
    }

    .offset-xl-10 {
        margin-left: 83.333333%
    }

    .offset-xl-11 {
        margin-left: 91.666667%
    }

    .container {
        padding-right: 15px;
        padding-left: 15px
    }
    .container {
        width: 1140px;
        max-width: 100%
    }

    .container-fluid {
        padding-right: 15px;
        padding-left: 15px
    }

    .row {
        margin-right: -15px;
        margin-left: -15px
    }
    .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9 {
        padding-right: 15px;
        padding-left: 15px
    }
}



